<template>
  <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center>
    <div class="w-70%">
      <div class="flex jc-between pb-10">服务名称<span class="weight-600">{{data.goodsName}}</span></div>
      <div class="flex jc-between">退款总金额<span class="weight-600">￥{{data.amount}}</span></div>
    </div>
    <span slot="footer" class="dialog-footer">
      <ch-button @click="centerDialogVisible = false">取 消</ch-button>
      <ch-button type="ok" :loading="submitLoading" @click="onsubmit">确 定</ch-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data(){
    return {
      centerDialogVisible: false,
      submitLoading: false,
      data:{},
    }
  },
  methods:{
    openRefund(data){
      this.data = JSON.parse(JSON.stringify(data))
      this.centerDialogVisible = true
    },
    onsubmit(){
      this.submitLoading = true
      this.$curl.post(`/hm/order/refund`,{
          orderId: this.data.id,
          cause:'平台取消'
        }).then(() => {
      }).finally(()=> {
        this.submitLoading = false
        this.centerDialogVisible = false
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>